  // allie-dev
  export const environment = {
    production: false,
    serverUrl: 'https://vyksal635d.execute-api.us-east-2.amazonaws.com/dev',
    bucket: 'allie-dev-upload',
    userPool: {
      UserPoolId: 'us-east-2_NtOu0zFLv',
      ClientId: '7vt024plv3hp2ofelaij6tc2n8',
      region: 'us-east-2'
    },
    identityPoolId: 'us-east-2:6ff9125e-70d7-46a2-91b8-eccda098fd3e',
    buckets: {
      'us-east-2': 'allie-dev-upload'
    },
    defaultRegion: 'us-east-2',
    cognito: {
      identityPoolId:'us-east-2:6ff9125e-70d7-46a2-91b8-eccda098fd3e', //REQUIRED - Amazon Cognito Identity Pool ID
      region: 'us-east-2', // REQUIRED - Amazon Cognito Region
      userPoolId: 'us-east-2_NtOu0zFLv', //OPTIONAL - Amazon Cognito User Pool ID
      userPoolWebClientId: '7vt024plv3hp2ofelaij6tc2n8', //OPTIONAL - Amazon Cognito Web Client ID
    },
    Storage: {
      AWSS3: {
        bucket: 'allie-dev-upload', //REQUIRED -  Amazon S3 bucket
        region: 'us-east-2', //OPTIONAL -  Amazon service region
      }
    }
  };

const awsconfig = {
  'aws_project_region': 'us-east-2',
  'aws_cognito_region': 'us-east-2',
  'aws_user_pools_id': 'us-east-2_NtOu0zFLv',
  'aws_user_pools_web_client_id': '7vt024plv3hp2ofelaij6tc2n8',
  'aws_cognito_mfa_configuration': 'OPTIONAL',

};
export default awsconfig;