import { Component, OnInit, ViewChild } from '@angular/core';
import { SharedService } from '../../layouts/shared-service';
import { AuthService, DBService, DownLoadService } from '../../service';
import { Router } from '@angular/router';
import { DropDownService } from '../../service/drop-down.service';
import { User } from '../../model/types';
import { AnalysisKey, AuthLog, Logs, PreviousAnalysis } from '../../model/analysis-details.model';
import Utils from '../../utility/Utils';
import { MatDatepicker } from '@angular/material/datepicker';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import _moment from 'moment';
import {default as _rollupMoment, Moment} from 'moment';
import { FormControl } from '@angular/forms';
const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
  providers: [ {
    provide: DateAdapter,
    useClass: MomentDateAdapter,
    deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS}
  ]
})
export class UserComponent implements OnInit {
  date = new FormControl(moment());
  pageTitle = 'User Account';
  signedInUser: any;
  loading = false;
  username: string;
  email: string;
  reportKey = '';
  fileLink;
  logdata: Array<AuthLog> = [];
  users: any[] = [];
  previousAnalysisKeys: AnalysisKey[] = [];
  pAfileLink;
  loginSelected = '';
  ctrlValueMonth;
  ctrlValueYear;
  monthSelected = '';
  maxYear;
  temp_loginData: any;
  temp_analysisData: any;
  clicked = false;
  months = ['January', 'Feburary', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  pageLoaded = false;
  title = 'Loading';
  @ViewChild('datePicker') public dp;



  constructor(private _sharedService: SharedService,
    private authService: AuthService,
    private router: Router,
    private dbService: DBService,
    private dropDownService: DropDownService,
    private downloadService: DownLoadService) {
    this.temp_loginData = JSON.parse(sessionStorage.getItem('login-data'));
    this.temp_analysisData = JSON.parse(sessionStorage.getItem('analysis-data'));
    this._sharedService.emitChange(this.pageTitle);
    // if(this.logdata.length==0){
    //   this.openDialog()
    // }
    // if(this.pageLoaded == false){
    //   this.openDialog()
    // }
  }
  // openDialog(){
  //   this.loader.open(this.title);
  //   setTimeout(() => {
  //     this.loader.close();
  //   }, 10000)
  // }
  // closeDialog(){
  //   console.log("dialog closed")
  //   this.loader.close();
  // }

  ngOnInit(): void {
    const currentYear = moment().toDate();
    this.maxYear = currentYear;
    this.authService.getCurrentUser((err, user: User) => {
      this.signedInUser = user;
      if (!this.signedInUser || !this.signedInUser.signedIn) {
        this.router.navigate(['/extra-layout/signin']);
        return;
      } else {
        this.authService.getUserAttributes((err1, userAttributes) => {
          this.loading = false;
          if (err1 || !userAttributes) {
            // console.error(err, new Error('Unable to retrive user attributes.'));
            this.router.navigate(['/extra-layout/signin']);
          }
          this.email = userAttributes['email'];
          const path = [this.signedInUser.username, this.signedInUser.userId].join('/');
          if (this.temp_loginData == null) {
            this.dbService.getAuthData(this.signedInUser.username, null, path);
          } else {
            this.logdata = this.temp_loginData.data;
            this.reportKey = this.temp_loginData.file;
            this.fileLink = this.getSingedURL(this.reportKey);
            for (const name of this.temp_loginData.users) {
              this.users.push({
                value: name, viewValue: name
              })
            }
          }
          if (this.temp_analysisData == null) {
            this.dbService.getAnalysis(this.signedInUser.username, null, path);
          } else {
            const util = new Utils(this.dropDownService.nameDescMap)
            this.previousAnalysisKeys = util.restructureSearch(this.temp_analysisData);
            this.pAfileLink = this.getSingedURL('AnalysisReport.pdf');
          }
        });
        this.username = user.username;
        this.dbService.authLogs.subscribe(
          (data: Logs) => {
            this.logdata = data.data;
            // console.log(this.logdata);
            this.reportKey = data.file
            // console.log(this.reportKey);
            this.fileLink = this.getSingedURL(this.reportKey)
            // if(this.logdata.length > 0){
            //   this.closeDialog()
            // }
            for (const name of data.users) {
              this.users.push({
                value: name, viewValue: name
              })
            }
          });

        this.dbService.userSearch.subscribe(
          (data: PreviousAnalysis[]) => {
            const util = new Utils(this.dropDownService.nameDescMap)
            this.previousAnalysisKeys = util.restructureSearch(data);
            this.pAfileLink = this.getSingedURL('AnalysisReport.pdf');

          }
        );
      }
    });
  }
  chosenYearHandler(normalizedYear: Moment) {
    this.ctrlValueYear = this.date.value;
    this.ctrlValueYear.year(normalizedYear.year());
    this.date.setValue(this.ctrlValueYear);
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    this.ctrlValueMonth = this.date.value;
    this.ctrlValueMonth.month(normalizedMonth.month());
    this.date.setValue(this.ctrlValueMonth);
    datepicker.close();
  }

  public getFormattedDate(analysisId) {
    const myDate = new Date(parseInt(analysisId, 10));
    // console.log(myDate);
    return myDate.toLocaleString();
  }

  fetchUserData() {
    const path = [this.signedInUser.username, this.signedInUser.userId].join('/');
    const date = this.date.value._d.toLocaleString();
    console.log(date);
    this.previousAnalysisKeys = [];
    this.logdata = [];
    this.users = [];
    this.dbService.getAnalysis(this.loginSelected, date, path);
    this.dbService.getAuthData(this.loginSelected, date, path);
    this.pAfileLink = this.getSingedURL('AnalysisReport.pdf');
    this.pageLoaded = true;
    this.clicked = true;
    this.dbService.authLogs.subscribe(
      (data: Logs) => {
        // console.log(data);
        this.logdata = data.data;
        // console.log(this.logdata);
        this.reportKey = data.file
        // console.log(this.reportKey);
        this.fileLink = this.getSingedURL(this.reportKey)
        for (const name of data.users) {
          this.users.push({
            value: name, viewValue: name
          })
        }
      });
  }


  getSingedURL(path: string) {
    path = [this.signedInUser.username, this.signedInUser.userId, path].join('/');
    if (/\S/.test(path)) {
      return this.downloadService.getUrl(path);
    } else {
      return ' ';
    }

  }

}


